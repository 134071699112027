







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import MicroModal from 'micromodal'
import { MyFolder } from '@/types/dango'

export default Vue.extend({
  data() {
    return {
      path: '/',
      isDisabled: true,
      isDisabled2: true,
      hznKey: '',
      newHznKey: '',
      uuidBox: [],
      quote: '',
      memo: {} as { [uuid: string]: string },
      checkedMemos: [],
      srchConditions: {} as { [uuid: string]: {} },
      page: this.$$getInitialValue('page'),
      detail: this.$$getInitialValue('detail'),
      srchInfo: this.$$getInitializedSrchInfo([this.$$const.API.srchId.KIJI]),
      searchApi: this.$$getInitialValue('searchApi'),
      detailApi: this.$$getInitialValue('detailApi'),
      scrollY: 0,
    }
  },
  watch: {
    // 親コンポーネントの値を監視する
    hznKey(newVal) {
      if (newVal !== '') {
        this.isDisabled2 = false
      }
    },

    // デザイン用のメモチェックボックスの値を監視する
    checkedMemos(newArr, oldArr) {
      console.log('checkedMemos', newArr, oldArr)
      if (newArr.length === 0 && oldArr.length === 1) {
        const i = oldArr[0][0]
        const uuid = oldArr[0][1]
        const idName = oldArr[0][2]
        console.log(JSON.parse(this.detail.FAVORITE_KIJI[i].memo))
        console.log(this.memo[uuid])
        if (JSON.parse(this.detail.FAVORITE_KIJI[i].memo) !== this.memo[uuid]) {
          if (confirm(String(this.$t('message.confirmContinue')))) {
            const el = document.getElementById(idName + i) as HTMLInputElement
            if (el) {
              console.log(el)
              const self = this
              setTimeout(function () {
                self.checkedMemos = oldArr
                el.checked = true
              }, 1)
            }
          } else {
            this.memo[uuid] = JSON.parse(this.detail.FAVORITE_KIJI[i].memo)
          }
        }
      }
    },
  },
  mounted() {
    // モーダル
    MicroModal.init({
      disableScroll: true,
      awaitCloseAnimation: true,
      awaitOpenAnimation: true,
      onShow: () => {
        if (this.$$getLoginInfo('S_HZNFOLDER')) {
          // ダッシュボードを表示
          this.switchPath('/dashboard')
        } else if (
          this.path === '/makeMyFolder' ||
          this.path === '/restoreMyFolder'
        ) {
          // TOPを表示
          this.switchPath('/')
        }
      },
    })

    // スクロール位置（Y座標）をセットする
    const el = document.getElementById('myFolderModalOverlay')
    if (el) {
      const self = this
      el.addEventListener('scroll', function () {
        self.scrollY = el.scrollTop
      })
    }
  },
  methods: {
    /**
     * 保存キーを作成する
     * @return {Promise<void>}
     */
    async makeHznKey(): Promise<void> {
      // マイフォルダ用保存キーを作成する
      this.hznKey = await this.$$makeMyFolderHznKey()
      if (this.hznKey !== '') {
        // マイフォルダ作成ページを表示
        this.switchPath('/makeMyFolder')
      }
    },

    /**
     * マイフォルダを作成する
     * @return {Promise<void>}
     */
    async makeMyFolder(): Promise<void> {
      // マイフォルダを作成する
      if (await this.$$makeMyFolder(this.hznKey)) {
        // 親コンポーネントに値を渡す
        this.$nuxt.$emit('setHznHolder', this.$$getLoginInfo('S_HZNFOLDER'))

        // ダッシュボードを表示
        this.switchPath('/dashboard')
      }
    },

    /**
     * マイフォルダを復元する
     * @return {Promise<void>}
     */
    async restoreMyFolder(): Promise<void> {
      // マイフォルダを復元する
      if (await this.$$restoreMyFolder(this.hznKey)) {
        // 親コンポーネントに値を渡す
        this.$nuxt.$emit('setHznHolder', this.$$getLoginInfo('S_HZNFOLDER'))

        // ダッシュボードを表示
        this.switchPath('/dashboard')
      }
    },

    /**
     * マイフォルダを複製する
     * @return {Promise<void>}
     */
    async copyMyFolder(): Promise<void> {
      // マイフォルダを複製する
      this.newHznKey = await this.$$copyMyFolder()
      if (this.newHznKey !== '') {
        // マイフォルダコピーページを表示
        this.switchPath('/copyMyFolder')
      }
    },

    /**
     * マイフォルダを閉じる
     * @return {Promise<void>}
     */
    async closeMyFolder(): Promise<void> {
      // マイフォルダ利用を解放する
      const res = await this.$$releaseMyFolder()
      if (res) {
        // 親コンポーネントに値を渡す
        this.$nuxt.$emit('setHznHolder', this.$$getLoginInfo('S_HZNFOLDER'))

        // マイフォルダ情報を初期化する
        this.$$initMyFolderInfo()

        // mainコンポーネントを再描画する
        this.$emit('refreshMainVue')

        // マイフォルダモーダルを閉じる
        MicroModal.close('myFolderModal')

        // パスをTOPに戻す
        this.switchPath('/')
      }
    },

    /**
     * マイフォルダ保存情報を削除する
     * @params string key
     * @params string uuid
     * @return {Promise<void>}
     */
    async deleteMyFolderInfo(key: string, uuid: string): Promise<void> {
      let meisaiId = ''
      if (uuid !== null) {
        meisaiId = uuid
      } else {
        if (this.uuidBox.length === 0) {
          return
        }
        meisaiId = this.uuidBox.join(',')
      }

      // マイフォルダ保存情報を削除する
      const res = await this.$$deleteMyFolderInfo(
        this.$$const.API.kinouId[key],
        meisaiId
      )
      if (res) {
        // ページをリフレッシュ
        await this.switchPath(this.path)

        // mainコンポーネントを再描画する
        this.$emit('refreshMainVue')
      }
    },

    /**
     * 保存キーをコピーする
     * @return {void}
     */
    copyHznKey(): void {
      const self = this
      navigator.clipboard
        .writeText(this.hznKey)
        .then(() => {
          this.isDisabled = false
          alert(self.$t('message.copySuccess'))
        })
        .catch((e) => {
          alert(self.$t('message.copyFailed'))
          console.log(e)
        })
    },

    /**
     * 複製した保存キーをコピーする
     * @return {void}
     */
    copyNewHznKey(): void {
      const self = this
      navigator.clipboard
        .writeText(this.newHznKey)
        .then(() => {
          this.isDisabled = false
          alert(self.$t('message.copySuccess'))
        })
        .catch((e) => {
          alert(self.$t('message.copyFailed'))
          console.log(e)
        })
    },

    /**
     * 一番上へにスクロールする
     * @params {boolean} smooth
     * @return {void}
     */
    scrollToTop(smooth: boolean = false): void {
      const el = document.getElementById('myFolderModalOverlay')
      if (el) {
        el.scrollTo({
          top: 0,
          left: 0,
          behavior: smooth ? 'smooth' : 'auto',
        })
      }
    },

    /**
     * パスを切り替える
     * @params {string} path
     * @params {number} page
     * @return {Promise<void>}
     */
    async switchPath(path: string, page: number = 1): Promise<void> {
      // パスを切り替える
      this.path = path
      console.log('path', this.path)

      // 変数初期化
      this.isDisabled = true
      this.isDisabled2 = true
      this.uuidBox = []
      this.memo = {}
      this.srchConditions = {}
      this.detail = this.$$getInitialValue('detail')
      this.srchInfo = this.$$getInitializedSrchInfo([
        this.$$const.API.srchId.KIJI,
      ])
      this.searchApi = this.$$getInitialValue('searchApi')
      this.detailApi = this.$$getInitialValue('detailApi')

      // マイフォルダ情報を初期化する
      this.$$initMyFolderInfo()

      // 一番上へスクロール
      this.scrollToTop()

      if (path === '/') {
        // 保存キーの値を初期化
        this.hznKey = ''
      } else if (path === '/dashboard') {
        // マイフォルダJSONを取得する
        await this.$$getMyFolderJson(['PRESEARCH', 'QUOTE', 'FAVORITE_KIJI'])

        // 開始終了位置
        const start = 0
        const end1 = this.$$const.MY_FOLDER.DASHBOARD1_DISP_NUM
        const end2 = this.$$const.MY_FOLDER.DASHBOARD2_DISP_NUM

        // レンダリング用の変数にセットする
        for (const detail of this.$$getMyFolderInfo(
          'PRESEARCH',
          'detail'
        ).slice(start, end2)) {
          this.$set(
            this.srchConditions,
            detail.uuid,
            this.$$getInitialValue('srchCondition')
          )
        }
        this.detail.PRESEARCH = this.$$getMyFolderInfo(
          'PRESEARCH',
          'detail'
        ).slice(start, end2)

        // 詳細情報を取得する
        const keys = ['QUOTE', 'FAVORITE_KIJI'] as (keyof MyFolder.detailIF)[]
        await this.getDetail(keys, start, end1)

        // レンダリング用の変数にセットする
        for (const k of keys) {
          if (k === 'FAVORITE_KIJI') {
            for (const detail of this.$$getMyFolderInfo(k, 'detail').slice(
              start,
              end1
            )) {
              let memo = ''
              if (detail.memo) {
                memo = JSON.parse(detail.memo)
              }
              this.$set(this.memo, detail.uuid, memo)
            }
          }
          this.detail[k] = this.$$getMyFolderInfo(
            k as keyof MyFolder.detailIF,
            'detail'
          ).slice(start, k === 'FAVORITE_KIJI' ? end1 : end2)
        }
      } else if (
        path === '/quote' ||
        path === '/presearch' ||
        path === '/favoriteKiji'
      ) {
        let k = ''
        if (path === '/quote') {
          k = 'QUOTE'
        } else if (path === '/presearch') {
          k = 'PRESEARCH'
        } else if (path === '/favoriteKiji') {
          k = 'FAVORITE_KIJI'
        }
        // マイフォルダJSONを取得する
        const keys = [k] as (keyof MyFolder.detailIF)[]
        await this.$$getMyFolderJson(keys)

        // 開始終了位置、ページ番号をセット
        const start = this.$$const.MY_FOLDER.DETAIL_DISP_NUM * (page - 1)
        const end = start + this.$$const.MY_FOLDER.DETAIL_DISP_NUM
        this.page[k] = page

        if (path === '/quote' || path === '/favoriteKiji') {
          // 詳細情報を取得する
          const keys = [k] as (keyof MyFolder.detailIF)[]
          await this.getDetail(keys, start, end)
        }

        if (k === 'FAVORITE_KIJI') {
          for (const detail of this.$$getMyFolderInfo(k, 'detail').slice(
            start,
            end
          )) {
            // メモ表示用の変数に値をセット
            let memo = ''
            if (detail.memo !== undefined) {
              memo = JSON.parse(detail.memo)
            }
            this.$set(this.memo, detail.uuid, memo)
          }
        } else if (k === 'PRESEARCH') {
          for (const detail of this.$$getMyFolderInfo(k, 'detail').slice(
            start,
            end
          )) {
            const srchInfo = JSON.parse(detail.srchInfo)[0]
            // 検索条件をローカライズする
            const srchCondition = this.$$localizePresearch(srchInfo)
            // 検索条件表示用の変数に値をセット
            this.$set(this.srchConditions, detail.uuid, srchCondition)
          }
        }
        // レンダリング用の変数にセットする
        this.detail[k] = this.$$getMyFolderInfo(
          k as keyof MyFolder.detailIF,
          'detail'
        ).slice(start, end)
      }
    },

    /**
     * 詳細情報を取得する
     * @params {(keyof MyFolder.detailIF)[]} keys
     * @params {number} start
     * @params {number} end
     * @return {void}
     */
    async getDetail(
      keys: (keyof MyFolder.detailIF)[],
      start: number,
      end: number
    ): Promise<void> {
      // 記事IDをセットする
      for (const k of keys) {
        for (const detail of this.$$getMyFolderInfo(
          k as keyof MyFolder.detailIF,
          'detail'
        ).slice(start, end)) {
          this.detailApi.itemInfo.push([detail.kijiId])
        }
      }
      if (this.detailApi.itemInfo.length > 0) {
        // 詳細情報を取得する
        await this.$$getDetail()
        for (const list of this.detailApi.result.lists) {
          this.detailApi.result2[list.KijiId] = list
        }
      }
    },

    /**
     * 詳細情報を表示する
     * @params {string} kijiId
     * @return {void}
     */
    openDetail(kijiId: string): void {
      this.detailApi.itemInfo = []
      this.detailApi.itemInfo.push([kijiId, 1])
      this.detailApi.result = []
      delete this.detailApi.srchOpt
      this.$$openNewWindow('/kiji/detail/', 0, '_blank')
    },

    /**
     * リスト検索する
     * @params {string} srchInfo
     * @return {void}
     */
    search(srchInfo: string): void {
      // 記事検索パラメータをセット
      this.srchInfo = JSON.parse(srchInfo)

      // リスト検索ページに遷移する
      this.$$openNewWindow('/kiji/', 0, '_self')
    },

    /**
     * mouseover時のイベントハンドラ
     * @return {void}
     */
    mouseover(e: any): void {
      if (!window.matchMedia('(min-width: 769px)').matches) {
        return
      }
      const el = e.target
      if (!el) {
        return
      }
      const p = el.parentNode
      if (!p) {
        return
      }
      el.classList.add('hover')
      p.classList.add('active')
    },

    /**
     * mouseleave時のイベントハンドラ
     * @return {void}
     */
    mouseleave(e: any): void {
      if (!window.matchMedia('(min-width: 769px)').matches) {
        return
      }
      const el = e.target
      if (!el) {
        return
      }
      const p = el.parentNode
      if (!p) {
        return
      }
      el.classList.remove('hover')
      p.classList.remove('active')
    },

    /**
     * mouseover時のイベントハンドラ
     * @return {void}
     */
    mouseover2(e: any): void {
      if (!window.matchMedia('(min-width: 769px)').matches) {
        return
      }
      const el = e.target
      if (!el) {
        return
      }
      const p = el.parentNode
      if (!p) {
        return
      }
      const pp = p.parentNode
      if (!pp) {
        return
      }
      p.classList.add('hover')
      pp.classList.add('active')
    },

    /**
     * mouseleave時のイベントハンドラ
     * @return {void}
     */
    mouseleave2(e: any): void {
      if (!window.matchMedia('(min-width: 769px)').matches) {
        return
      }
      const el = e.target
      if (!el) {
        return
      }
      const p = el.parentNode
      if (!p) {
        return
      }
      const pp = p.parentNode
      if (!pp) {
        return
      }
      p.classList.remove('hover')
      pp.classList.remove('active')
    },
  },
})
